<template>
    <div><b-button class="mb-2" variant="danger" @click="followLink">Login To Portal As {{ type }}</b-button></div>
</template>

<script>
import axios from '../shared/axios/auth-no-loader'

export default {
  props: {
    id: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      link: null
    }
  },
  methods: {
    setupButton() {
      axios.get('/users/' + this.id + '/portal-link/').then(
        response => {
          console.log("GENERATED LINK", response.data);
          this.link = response.data.generated_link
        }
      ).catch(error => {
        // Unauthorised
        if (error.response.status === 401) {
          this.$store.dispatch('logout');
        }
        console.log('premium error');
        console.log(error.response)
      });
    },
    followLink() {
      if (this.link) {
        window.open(this.link, "_blank");
      }
    }
  },
  mounted() {
    this.setupButton();
  }
}
</script>